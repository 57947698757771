<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="优惠券名称">
            <el-input v-model="search.name" maxlength="30" placeholder="请输入优惠券名称" />
          </el-form-item>
          <el-form-item label="优惠券类型">
            <el-select v-model="search.type" clearable placeholder="请选择优惠券类型">
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <!-- <el-button  v-if="!userInfo.shop_id" type="primary" :disabled="!idList.length" class="mb10" size="mini" @click="tapBtn('export')">导出数据</el-button> -->
      <el-button  v-if="userInfo.id == 444 || userInfo.id == 1" type="primary" class="mb10" size="mini" @click="tapBtn('one')" v-loading.fullscreen.lock="fullscreenLoading" >一键发券</el-button>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn  v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="优惠券名称" prop="name" />
            <ElTableColumn label="优惠券类型" prop="type" width="100" />
            <ElTableColumn label="使用规则" prop="rule" />
            <ElTableColumn label="面值(元)" prop="denomination" width="150" />
            <ElTableColumn label="优惠券状态" prop="status_desc" width="100" />
            <ElTableColumn label="发放状态" prop="provide_status_desc" width="100" />
            <ElTableColumn label="有效期" width="200">
              <template slot-scope="{ row }">
                <div>{{ row.start_time }} 至 {{ row.end_time }}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="操作" fixed="right" width="200">
              <template slot-scope="{ row }">
                <el-button type="text" @click="tapBtn('detail',row.id)">查 看</el-button>
                <el-button
                  type="text"
                  :disabled="row.status == 2 || row.provide_status == 1"
                  @click="tapBtn('update',row.id)"
                >编 辑</el-button>
                <el-button
                  type="text mr10"
                  :disabled="row.status == 2 || row.provide_status == 1"
                  @click="tapBtn('issue',row.id)"
                >发 放</el-button>
                <span class="peril" type="text" @click="tapBtn('del',row.id)">删 除</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>

    <el-dialog title="发放结果" :visible.sync="sendCause" :close-on-click-modal="false">
      <el-table
        :data="sendCauseRes"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          width="120"
          label="优惠券">
        </el-table-column>
        <el-table-column
          prop="vipType"
          width="120"
          label="会员等级">
        </el-table-column>
        <el-table-column
          prop="should_send"
          width="80"
          label="应发人数">
        </el-table-column>
        <el-table-column
          prop="actual_send"
          width="80"
          label="实际发放">
        </el-table-column>
        <el-table-column
          prop="cause"
          width="200"
          label="原因">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pagination from "@/components/Pagination";
import { getCardList, deleteCard,cardexport,oneProvide } from "@/api/card";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  name = ""; // 优惠券名称
  type = ""; // 优惠券类型
}
class Options {
  constructor() {
    this.type = [
      { id: 1, name: "满减券" },
      // { id: 2, name: "服务卡" },
      { id: 3, name: "满赠券" },
      { id: 4, name: "兑换券" }
    ]; // 代理人
  }
}
export default {
  name: "CardList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      sendCauseRes: [],
      idList: "",
      fullscreenLoading: false,
      sendCause: false,
    };
  },
computed: {
    ...mapGetters(['userInfo']),
  },
  activated() {},
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      getCardList({ ...this.search, ...this.page }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
    },
    // 点击详情
    tapBtn(type, id) {
      switch (type) {
        case "detail":
          this.$router.push("./cardDetail?id=" + id);
          break;
        case "update":
          this.$router.push("./addCard?id=" + id);
          break;
        case "issue":
          this.$router.push("./issueCard?id=" + id);
          break;
        case "del":
          this.hint(id, "删除此优惠券");
          console.log(id);
          break;
        case "export":
          this.hint("export", "导出选中列表");
          break;
        case "one":
          this.hint("one","一键发放会员券");
          break;
      }
    },
    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "export") {
            this.getExport();
          } else if(type == "one"){
            console.log(text);
            this.sendCard();
          } else {
            this.delCard(type);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      cardexport({ id: this.idList }).then(res => {
        window.open(res.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    },
    // 删除优惠券
    delCard(id) {
      deleteCard({ id: id }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.getList();
      });
    },

    sendCard(){
      this.fullscreenLoading = true;
      // console.log('一键发放');
      oneProvide({}).then(res => {
        console.log(res);
        this.sendCauseRes = res.data;
        this.fullscreenLoading = false;
        this.sendCause = true;
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
.el-button--text {
  padding: 0 0;
}
</style>
